<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Base1CLog',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Base1CLog',
      tableCaption: 'Логирование изменений в базах 1С',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Логирование изменений в базах 1С',
        },
      ],
      tableHeaders: [
        {
          text: 'Название клиента',
          alias: 'customerName',
          order: 'customerName',
        },
        {
          text: 'Имя базы (папка на диске)',
          alias: 'dbName',
          order: 'dbName',
        },
        {
          text: 'Действие',
          alias: 'action',
          order: 'action',
        },
        {
          text: 'Связанная запись',
          alias: 'base1CId',
          order: 'base1CId',
        },
        {
          text: 'Дата создания',
          alias: 'moment',
          order: 'moment',
        },
      ],
    };
  },
};
</script>
